body {
	padding: 0;
	margin: 0;
	background-color: #061a41;
	font-size: 12px;
	font-family: 'Days One', sans-serif;
}

.app {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100wh;
	padding: 3em 1em;
}

.mainContainer {
	max-width: 1000px;
	width: 100%;
}

.titleBarLayout {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.titleBarLayout .title {
	font-family: 'Red Hat Display', sans-serif;
	font-size: 1.3em;
	color: white;
	text-transform: capitalize;
}

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 0.5em;
	align-items: center;
	padding: 8px 12px;
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border-radius: 10px;
	outline: none;
	border: none;
	cursor: pointer;
	color: white;
	font-family: 'Days One', sans-serif;
}

.buttonSwitchNetwork {
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 0.5em;
	align-items: center;
	padding: 8px 12px;
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border-radius: 10px;
	outline: none;
	border: none;
	cursor: pointer;
	color: white;
	font-family: 'Days One', sans-serif;
}

.buttonAccountLabel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 0.5em;
	align-items: center;
	padding: 8px 12px;
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border-radius: 10px;
	outline: none;
	border: none;
	cursor: pointer;
	color: white;
	font-family: 'Days One', sans-serif;
}

.networkItem {
	width: 20em;
	height: 5em;
}

.smallButton {
	width: 2em;
	height: 2em;
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border-radius: 10px;
	outline: none;
	border: none;
	cursor: pointer;
	color: white;
	font-family: 'Days One', sans-serif;
}

.label {
	font-size: 12px;
	color: white;
	text-transform: uppercase;
	font-family: 'Days One', sans-serif;
}

.main {
	width: 100%;
	padding: 4em 0;
}

.menuLayout {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	column-gap: 1em;
}

.menuLayout .panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	background: radial-gradient(62.58% 62.58% at 18.62% 20.83%, rgba(222, 53, 255, 0.120269) 0%, rgba(222, 53, 255, 6e-05) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, rgba(0, 0, 0, 0.102082) 100%), linear-gradient(180deg, rgba(8, 50, 109, 0.94) 0%, rgba(4, 33, 88, 0.94) 100%);
	box-sizing: border-box;
	backdrop-filter: blur(4px);
	border-radius: 24px;
	padding: 10px;
	min-width: 50%;
	row-gap: 0.5em;
	padding: 2em;
}

.grayLabel {
	font-family: Jura;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.7px;
	text-transform: uppercase;
	color: #E8EBF3;
	mix-blend-mode: normal;
	opacity: 0.6;
}

.smallGrayLabel {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: #E8EBF3;
	opacity: 0.6;
	text-transform: uppercase;
}

.scoreLabel {
	font-family: Days One;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	text-transform: capitalize;
	color: #E8EBF3;
	mix-blend-mode: normal;
}

.normalButton {
	background: linear-gradient(45deg, #C724FF, #5D5BFF, #25B4FF);
	border: none;
	outline: none;
	padding: 1px;
	border-radius: 12px;
	cursor: pointer;
}

.normalButton div {
	padding: 12px 18px;
	background: linear-gradient(200deg, #25B4FF -200%, #5D5BFF 50%, #C724FF 200%);
	border-radius: 12px;
	font-family: Days One;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	text-transform: uppercase;
	color: #FFFFFF;
}

.normalButton:active div {
	background: linear-gradient(20deg, #25B4FF -200%, #5D5BFF 50%, #C724FF 200%);
}

table {
	width: 100%;
}

.tableHeader {
	/*  */
}

th {
	text-transform: uppercase;
	font-size: 10px;
	color: white;
	text-align: left;
	padding-bottom: 2em;
}

.titleRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.titleRow .interest {
	display: none;
}

td {
	color: white;
	font-size: 14px;
	padding-bottom: 0.8em;
}

td .logo {
	border-radius: 50%;
	margin-right: 0.5em;
}

.interestLabel {
	/*  */
}

.keyValueLabel {
	/*  */
}

.keyValueLabel .key {
	display: none;
}

.keyValueLabel .value {
	/*  */
}

.buttonCell {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.amountLabel {
	color: #8a8c91;
	font-family: rabik;
	font-size: 12px;
	font-family: 'Rubik', sans-serif;
}

.modalBackground {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(8px);
}

.modalLayout {
	background: radial-gradient(62.58% 62.58% at 18.62% 20.83%, rgba(222, 53, 255, 0.120269) 0%, rgba(222, 53, 255, 6e-05) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, rgba(0, 0, 0, 0.102082) 100%), linear-gradient(180deg, rgba(8, 50, 109, 0.94) 0%, rgba(4, 33, 88, 0.94) 100%);
	border-radius: 1em;
	width: fit-content;
	padding: 4em 2em 3em 2em;
	color: white;
	position: relative;
}

h3 {
	text-transform: capitalize;
}

.amountInputLayout {
	background-image: linear-gradient(to bottom, rgba(227, 85, 255, 0.06), rgba(227, 85, 255, 0.06)), linear-gradient(to bottom, #001744, #042158);
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 13px;
	padding-bottom: 13px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	column-gap: 0.5rem;
}

.amountInput {
	background: none;
	border: none;
	outline: none;
	color: white;
	font-size: 20px;
	width: 10rem;
}

.amountInputDisabled {
	filter: grayscale(100%);
	pointer-events: none;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

button:disabled {
	opacity: 0.6;
	pointer-events: none;
	cursor: not-allowed;
}

a {
	text-decoration: none;
}

.footerLayout {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: 'Rubik', sans-serif;
}

.twinLogoLayout {
	position: relative;
	width: 46px;
	height: 26px;
}

.twinLogoLayout .logo1 {
	/* width: 26px; */
	height: 26px;
}

.twinLogoLayout .logo2 {
	position: absolute;
	left: 20px;
	/* width: 26px; */
	height: 26px;
}

@media (max-width: 428px) {
	.buttonSwitchNetwork {
		display: none;
	}

	.buttonAccountLabel {
		display: none;
	}

	.menuLayout {
		padding: 0;
		margin: 0;
		justify-content: center;
	}

	.menuLayout .panel {
		min-width: 45%;
	}

	.tableHeader {
		display: none;
	}

	tr {
		display: flex;
		flex-direction: column;
		margin-bottom: 1em;
		padding: 2em 2em 1em 2em;
		background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
		border-radius: 2em;
	}

	.titleRow {
		width: 100%;
	}

	.titleRow .interest {
		display: block;
	}

	.interestLabel {
		display: none;
	}

	.keyValueLabel {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.keyValueLabel .key {
		display: block;
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: #E8EBF3;
		opacity: 0.6;
		text-transform: uppercase;
	}

	.keyValueLabel .value {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
	}

	.buttonCell {
		margin-top: 1em
	}
}